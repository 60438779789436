import { ExecutionResponse, IConnector } from "@unifiprotocol/core-sdk";
import { toHex } from "@unifiprotocol/utils";
import { ERC20ContractMethod } from ".";
import { ABI } from "../Pair/index";

type MethodOptions = {
  adapter?: IConnector;
};

export class ERC20 {
  constructor(private connector: IConnector, private erc20Addr: string) {
    this.initializeContract();
  }

  private async initializeContract() {
    await this.connector.connect();
    await this.connector.adapter!.adapter.initializeContract(
      this.erc20Addr,
      ABI
    );
  }

  public async allowance(
    owner: string,
    spender: string,
    options: MethodOptions = {}
  ): Promise<string> {
    await this.initializeContract();
    const response = await this.connector.adapter!.adapter.execute(
      this.erc20Addr,
      ERC20ContractMethod.Allowance,
      { args: [owner, spender] },
      false
    );
    return response.value;
  }

  public async approve(
    spender: string,
    amount: number | string,
    options: MethodOptions = {}
  ): Promise<ExecutionResponse<string>> {
    await this.initializeContract();
    const response = await this.connector.adapter!.adapter.execute(
      this.erc20Addr,
      ERC20ContractMethod.Approve,
      { args: [spender, toHex(amount)] },
      true
    );
    return response;
  }

  // get UseCase() {
  //   return Object.keys(this).reduce(
  //     (t: { [K in keyof Pair]?: () => {} }, curr) => {
  //       const methodName = curr as keyof Pair;
  //       if (methodName === "UseCase") return t;
  //       const method = this[methodName];
  //       t[methodName] = () => ({
  //         execute(adapter: IAdapter) {},
  //       });
  //       return t;
  //     },
  //     {}
  //   );
  // }
}
