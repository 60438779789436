import { Signer } from "ethers";
import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";
import { useAdapter } from "../Adapter";
import { useConfig } from "../Config";
import { Factory } from "../Contracts/Factory/Factory";
import { ILiquidityPool } from "../Entities/LiquidityPool";
import { Api } from "../Services/Api";
import { ContractFetcher } from "../Services/ContractFetcher";

type PoolState = {
  pools: ILiquidityPool[];
  whitelistedPools: ILiquidityPool[];
};

const poolAtom = atom<PoolState>({
  key: "poolState",
  default: { pools: [], whitelistedPools: [] },
});

export const usePools = () => {
  const [{ pools, whitelistedPools }, setPool] = useRecoilState(poolAtom);
  const { config } = useConfig();
  const { connection, adapter, blockchainConfig } = useAdapter();

  const fetchPools = useCallback(async () => {
    if (!config) return [];
    const factory = new Factory(connection, config.factory);
    const pairsLength = await factory.allPairsLength();
    const pairsAddresses = await factory.allPairsBatch(
      Array.from(Array(pairsLength).keys())
    );
    const pools = await Promise.all(
      pairsAddresses.map((addr) =>
        ContractFetcher.getLiquidityPool(addr, connection).then((pool) =>
          Api.getPoolSettings(blockchainConfig.blockchain, pool)
        )
      )
    );
    return pools;
  }, [config, connection, blockchainConfig]);

  const getWhitelistedPools = useCallback(async () => {
    if (!config || !adapter || !adapter?.getAddress()) {
      return [];
    }
    const factory = new Factory(connection, config.factory);
    const whitelist: ILiquidityPool[] = [];
    for (const pool of pools) {
      const isUserPool = await factory.depositorWhitelistedAddress(
        pool.poolAddress,
        adapter.getAddress()
      );
      if (isUserPool) whitelist.push(pool);
    }
    return whitelist;
  }, [adapter, config, connection, pools]);

  const setPools = (newPools: typeof pools) =>
    setPool((st) => ({ ...st, pools: newPools }));

  const setWhitelistedPools = (newWhitelistedPools: typeof whitelistedPools) =>
    setPool((st) => ({ ...st, whitelistedPools: newWhitelistedPools }));

  const updatePoolSettings = useCallback(
    async (pool: ILiquidityPool, { loggers, threshold, enabled }) => {
      const body = {
        ...(loggers !== undefined ? { loggers } : {}),
        ...(threshold !== undefined ? { threshold } : {}),
        ...(enabled !== undefined ? { enabled } : {}),
      };

      const signer: Signer = adapter?.getProvider().getSigner();
      const signature = await signer.signMessage(JSON.stringify(body));
      const signerAddress = await signer?.getAddress();
      const auth = `${signerAddress}:${signature}`;
      return Api.patchPoolSettings(
        blockchainConfig.blockchain,
        pool,
        body,
        auth
      );
    },
    [adapter, blockchainConfig]
  );

  return {
    whitelistedPools,
    pools,
    setPools,
    fetchPools,
    setWhitelistedPools,
    getWhitelistedPools,
    updatePoolSettings,
  };
};
