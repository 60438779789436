import { IConnector } from "@unifiprotocol/core-sdk";
import { ABI, PairContractMethod } from ".";

type MethodOptions = {
  adapter?: IConnector;
};

export class Pair {
  constructor(private connector: IConnector, private pairAddress: string) {
    this.initializeContract();
  }

  private async initializeContract() {
    await this.connector.connect();
    await this.connector.adapter!.adapter.initializeContract(
      this.pairAddress,
      ABI
    );
  }

  public async token0(options: MethodOptions = {}): Promise<string> {
    await this.initializeContract();
    const response = await this.connector.adapter!.adapter.execute(
      this.pairAddress,
      PairContractMethod.Token0,
      {},
      false
    );
    return response.value;
  }

  public async token1(options: MethodOptions = {}): Promise<string> {
    await this.initializeContract();
    const response = await this.connector.adapter!.adapter.execute(
      this.pairAddress,
      PairContractMethod.Token1,
      {},
      false
    );
    return response.value;
  }

  public async getReserves(
    options: MethodOptions = {}
  ): Promise<[string, string]> {
    await this.initializeContract();
    const response = await this.connector.adapter!.adapter.execute(
      this.pairAddress,
      PairContractMethod.GetReserves,
      {},
      false
    );
    const result = response.value;
    return [result[0], result[1]];
  }

  public async getMultiplier(options: MethodOptions = {}): Promise<string> {
    await this.initializeContract();
    const response = await this.connector.adapter!.adapter.execute(
      this.pairAddress,
      PairContractMethod.Multiplier,
      {},
      false
    );
    const result = response.value;
    return result;
  }

  public async getTotalSupply(options: MethodOptions = {}): Promise<string> {
    await this.initializeContract();
    const response = await this.connector.adapter!.adapter.execute(
      this.pairAddress,
      PairContractMethod.TotalSupply,
      {},
      false
    );
    const result = response.value;
    return result;
  }

  public async getActualReserves(
    options: MethodOptions = {}
  ): Promise<[string, string]> {
    await this.initializeContract();
    const response = await this.connector.adapter!.adapter.execute(
      this.pairAddress,
      PairContractMethod.GetActualReserves,
      {},
      false
    );
    const result = response.value;
    return [result[0], result[1]];
  }

  // get UseCase() {
  //   return Object.keys(this).reduce(
  //     (t: { [K in keyof Pair]?: () => {} }, curr) => {
  //       const methodName = curr as keyof Pair;
  //       if (methodName === "UseCase") return t;
  //       const method = this[methodName];
  //       t[methodName] = () => ({
  //         execute(adapter: IAdapter) {},
  //       });
  //       return t;
  //     },
  //     {}
  //   );
  // }
}
