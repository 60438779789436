import { BN, ifNanThen } from "@unifiprotocol/utils";
import { useMemo } from "react";
import styled from "styled-components";
import { useAdapter } from "../../../../Adapter";
import {
  ILiquidityPool,
  LiquidityPool,
} from "../../../../Entities/LiquidityPool";

const PoolStatsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 1rem;
`;

const PoolStat = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const LightText = styled.b`
  color: rgb(181, 181, 181);
  font-weight: 300;
  font-size: 90%;
`;

const BoldText = styled.b`
  font-weight: 600;
  font-size: 120%;
`;

const PrimaryText = styled(BoldText)`
  color: ${({ theme }) => theme.primary};
`;

export const PoolStats = ({ autopool }: { autopool: ILiquidityPool }) => {
  const { getBalanceByCurrency } = useAdapter();
  const liquidityPool = useMemo(() => new LiquidityPool(autopool), [autopool]);

  const oppositePrice = useMemo(
    () => ifNanThen(BN(1).div(liquidityPool.getPrice()).dp(4).toFixed(), "0"),
    [liquidityPool]
  );

  const userShare = useMemo(() => {
    if (!liquidityPool) return "0";
    const lpBalance = getBalanceByCurrency(liquidityPool.poolToken).balance;
    return BN(lpBalance).div(liquidityPool.totalSupply).toFixed();
  }, [getBalanceByCurrency, liquidityPool]);

  return (
    <PoolStatsWrapper>
      <PoolStat>
        <BoldText>{liquidityPool.getPrice()}</BoldText>
        <LightText>
          {liquidityPool.token0.symbol} per {liquidityPool.token1.symbol}
        </LightText>
      </PoolStat>
      <PoolStat>
        <PrimaryText>
          ~{BN(userShare).multipliedBy(100).dp(2).toFixed()}%
        </PrimaryText>
        <LightText>Your share of pool</LightText>
      </PoolStat>
      <PoolStat>
        <BoldText>{oppositePrice}</BoldText>
        <LightText>
          {liquidityPool.token1.symbol} per {liquidityPool.token0.symbol}
        </LightText>
      </PoolStat>
    </PoolStatsWrapper>
  );
};
