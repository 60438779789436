import { PrimaryButton } from "@unifiprotocol/uikit";
import styled from "styled-components";

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  button {
    flex: 3;
  }
  div {
    text-align: center;
    flex: 1;
  }
`;

export const PaddedPrimaryButton = styled(PrimaryButton)`
  font-size: 1rem !important;
  padding: 1rem !important;
  color: #000 !important;
`;

export const PrimaryIconWrapper = styled.div`
  color: ${({ theme }) => theme.primary};
`;
