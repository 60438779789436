import { Table } from "@unifiprotocol/uikit";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { AddLiquidityController as AddLiquidityModalController } from "../AddLiquidity";
import { AutopoolCard } from "../AutopoolCard";
import { SettingsController as SettingsModalController } from "../Settings";
import { RemoveLiquidityController as RemoveLiquidityModalController } from "../RemoveLiquidity";
import { usePools } from "../../../../Pool";
import { ILiquidityPool } from "../../../../Entities/LiquidityPool";

export const LiquidityPoolRowWrapper = styled.tbody`
  &:before {
    content: "";
    display: block;
    /* height: 1.5rem; */
  }
  ${(props) => props.theme.mediaQueries.xs} {
    &:before {
      content: "";
      display: block;
      height: 0.5rem;
    }
  }
`;

export type ActionType = "addLiquidity" | "removeLiquidity" | "settings";

export type ActionArgs = {
  action?: ActionType;
  pool?: ILiquidityPool | undefined;
};

export const AutopoolsTable = () => {
  const { whitelistedPools, pools } = usePools();
  const [{ pool, action }, setAction] = useState<ActionArgs>({});

  // for faster refresh
  const mixedPools = useMemo(
    () =>
      pools.reduce((p: ILiquidityPool[], pool) => {
        if (whitelistedPools.some((p) => p.poolToken.equals(pool.poolToken))) {
          p.push(pool);
        }
        return p;
      }, []),
    [pools, whitelistedPools]
  );

  return (
    <>
      <AddLiquidityModalController
        onClose={() => setAction({})}
        isOpen={action === "addLiquidity"}
        modalProps={{ pool }}
      />
      <RemoveLiquidityModalController
        onClose={() => setAction({})}
        isOpen={action === "removeLiquidity"}
        modalProps={{ pool }}
      />
      <SettingsModalController
        onClose={() => setAction({})}
        isOpen={action === "settings"}
        modalProps={{ pool }}
      />
      <Table>
        {mixedPools.map((pool: ILiquidityPool, i: number) => (
          <AutopoolCard
            autopool={pool}
            onActionClick={(pool, action) => setAction({ pool, action })}
            key={i}
          />
        ))}
      </Table>
    </>
  );
};
