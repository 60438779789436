import { CollapsibleCard } from "@unifiprotocol/uikit";
import React, { useMemo } from "react";
import { useAdapter } from "../../Adapter";
import { usePools } from "../../Pool";
import { PoolWrapper } from "./Styles";

export const Summary = () => {
  const { connection } = useAdapter();
  const { pools } = usePools();

  const blockchainCfg = useMemo(() => connection.config, [connection]);

  return (
    <>
      <h1>Summary</h1>
      <PoolWrapper>
        {pools.map((pool, idx) => (
          <CollapsibleCard key={idx}>
            <div>
              <a
                href={blockchainCfg.explorer.address(pool.poolAddress)}
                target="_blank"
                rel="noreferrer"
              >
                {pool.token0.symbol}-{pool.token1.symbol}
              </a>
            </div>
            <ul>
              <li>{pool.multiplier}x</li>
              <li>
                R0 {pool.token0.toFactorized(pool.reserves0, 6)}{" "}
                {pool.token0.symbol}
              </li>
              <li>
                R1 {pool.token1.toFactorized(pool.reserves1, 6)}{" "}
                {pool.token1.symbol}
              </li>
            </ul>
            {pool.settings && (
              <>
                <div>Settings</div>
                <pre>{JSON.stringify(pool.settings, null, 4)}</pre>
              </>
            )}
          </CollapsibleCard>
        ))}
      </PoolWrapper>
    </>
  );
};
