import {
  Checkbox,
  Modal,
  ModalBody,
  ModalHeader,
  useModal,
} from "@unifiprotocol/uikit";
import { FC, useCallback, useEffect, useState } from "react";
import { ILiquidityPool } from "../../../../Entities/LiquidityPool";
import { LoggerConfig } from "./LoggerConfig";
import { Loggers } from "../../../../Loggers";
import {
  BasicInfo,
  CenteredContent,
  CheckboxContainer,
  InputPlaceholder,
  LighterInput,
  LoggersInfo,
  MarginedDangerButton,
  MarginedPrimaryButton,
  PrimaryTitle,
} from "./Styles";
import { usePools } from "../../../../Pool";
import { useAdapter } from "../../../../Adapter";
import { SuccessNotification } from "../../../../Components/Notifications";

const SettingsComponent: FC<{ pool?: ILiquidityPool; onClose: () => void }> = ({
  pool,
  onClose,
}) => {
  const { eventBus } = useAdapter();
  const [submitState, setSubmitState] = useState<"READY" | "ERROR" | "PENDING">(
    "READY"
  );
  const [loggers, setLogggers] = useState<Record<string, any>>({});
  const [threshold, setThreshold] = useState<string>(
    pool?.settings?.threshold.toString() ?? ""
  );
  const [enabled, setEnabled] = useState<boolean | undefined>(
    pool?.settings?.enabled
  );
  const [updated, setUpdated] = useState(false);

  const saveLoggerConfig = useCallback(
    (key, cfg) => {
      console.log(key, cfg);
      setLogggers((loggers) => ({ ...loggers, [key]: cfg }));
    },
    [setLogggers]
  );
  const { updatePoolSettings } = usePools();
  console.log(pool?.settings?.enabled);

  const onSubmit = useCallback(async () => {
    setSubmitState("PENDING");
    await updatePoolSettings(pool as ILiquidityPool, {
      loggers,
      threshold,
      enabled,
    })
      .then(() => {
        eventBus.emit(
          SuccessNotification(
            "New config submitted, the changes will take effect in a bit."
          )
        );
        onClose();
      })
      .catch((err) => {
        setSubmitState("ERROR");
        setTimeout(() => setSubmitState("READY"), 1500);
      });
  }, [
    updatePoolSettings,
    pool,
    loggers,
    threshold,
    eventBus,
    onClose,
    enabled,
  ]);

  if (!pool?.settings) {
    return (
      <Modal>
        <ModalHeader>Settings</ModalHeader>
        <ModalBody>An error ocurred, refresh page or try later</ModalBody>
      </Modal>
    );
  }
  const { settings, poolAddress } = pool;
  return (
    <Modal>
      <ModalHeader>Settings</ModalHeader>
      <ModalBody>
        <BasicInfo>
          <PrimaryTitle>General</PrimaryTitle>
          <InputPlaceholder>Pool address</InputPlaceholder>
          <LighterInput disabled={true} type="text" value={poolAddress} />
          <InputPlaceholder>Name</InputPlaceholder>
          <LighterInput disabled={true} type="text" value={settings.name} />
          <InputPlaceholder>Arbitrage pool address</InputPlaceholder>
          <LighterInput disabled={true} type="text" value={settings.arbPool} />
          <InputPlaceholder>Flashbot address</InputPlaceholder>
          <LighterInput disabled={true} type="text" value={settings.flashbot} />
          <InputPlaceholder>Reward token address</InputPlaceholder>
          <LighterInput
            disabled={true}
            type="text"
            value={settings.rewardToken}
          />
          <InputPlaceholder>Opportunity threshold (%)</InputPlaceholder>
          <LighterInput
            type="number"
            placeholder="Opportunity threshold (%)"
            value={threshold}
            onChange={(ev) => {
              setThreshold(ev.target.value);
              setUpdated(true);
            }}
          />
          <InputPlaceholder>Trading enabled</InputPlaceholder>
          <CheckboxContainer>
            <Checkbox
              checked={enabled!}
              onChange={() => {
                const _enabled = !enabled;
                setEnabled(_enabled);
                setUpdated(_enabled !== (pool.settings?.enabled ?? false));
              }}
            />
          </CheckboxContainer>
        </BasicInfo>
        <LoggersInfo>
          <PrimaryTitle>Loggers</PrimaryTitle>
          {Loggers.map((logger) => (
            <LoggerConfig
              key={logger.key}
              isSetup={
                loggers[logger.key] ||
                (settings.loggers.includes(logger.key) &&
                  loggers[logger.key] !== null)
              }
              logger={logger}
              onSave={(cfg: any) => {
                saveLoggerConfig(logger.key, cfg);
                setUpdated(true);
              }}
            />
          ))}
          <CenteredContent>
            {
              {
                READY: (
                  <MarginedPrimaryButton onClick={onSubmit} disabled={!updated}>
                    Save
                  </MarginedPrimaryButton>
                ),
                PENDING: (
                  <MarginedPrimaryButton onClick={onSubmit} disabled={!updated}>
                    Saving...
                  </MarginedPrimaryButton>
                ),
                ERROR: (
                  <MarginedDangerButton onClick={onSubmit} disabled={!updated}>
                    Error saving =(
                  </MarginedDangerButton>
                ),
              }[submitState]
            }
            <MarginedPrimaryButton onClick={onClose}>
              Cancel
            </MarginedPrimaryButton>
          </CenteredContent>
        </LoggersInfo>
      </ModalBody>
    </Modal>
  );
};

export const SettingsController = ({
  modalProps,
  onClose,
  isOpen,
}: {
  modalProps: { pool?: ILiquidityPool };
  onClose: () => void;
  isOpen: boolean;
}) => {
  const [open, close] = useModal({
    component: SettingsComponent,
    props: { ...modalProps, onClose },
    options: {
      onClose: () => {
        isOpen && onClose();
      },
      disableBackdropClick: true,
    },
  });

  useEffect(() => {
    isOpen && modalProps.pool ? open() : close();
  }, [open, close, isOpen, modalProps.pool]);

  return <></>;
};
