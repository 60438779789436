import { Blockchains } from "@unifiprotocol/utils";
import { ApiBaseUrl } from "../Config";
import { ILiquidityPool } from "../Entities/LiquidityPool";

export class Api {
  public static async getPoolSettings(
    blockchain: Blockchains,
    pool: ILiquidityPool
  ) {
    try {
      const response = await fetch(
        `${ApiBaseUrl}/v1/autopools/${blockchain}/${pool.poolAddress}`
      );

      if (response.status < 200 || response.status > 299) {
        console.error(
          `Error fetching settings for autopool ${blockchain}/${pool.poolAddress}: Response code is ${response.status}`
        );
        return pool;
      }

      const body = await response.json();

      if (body.status !== "OK") {
        console.error(
          `Error fetching settings for autopool ${blockchain}/${pool.poolAddress}: API has failed`
        );
        return pool;
      }

      return { ...pool, settings: body.result };
    } catch (e) {
      return pool;
    }
  }

  public static async patchPoolSettings(
    blockchain: Blockchains,
    pool: ILiquidityPool,
    body: any,
    auth: string
  ) {
    const headers = new Headers();
    headers.set("Content-Type", "application/json");
    headers.set("Authorization", auth);
    const response = await fetch(
      `${ApiBaseUrl}/v1/autopools/${blockchain}/${pool.poolAddress}`,
      {
        method: "PATCH",
        body: JSON.stringify(body),
        headers,
      }
    );

    if (response.status < 200 || response.status > 299) {
      throw new Error(
        `Error updating settings for autopool ${blockchain}/${pool.poolAddress}: Response code is ${response.status}`
      );
    }

    const { status, errors }: { errors?: string[]; status: "OK" | "ERROR" } =
      await response.json();

    if (errors || status !== "OK") {
      throw new Error(
        `Error updating settings for autopool ${blockchain}/${
          pool.poolAddress
        }: API has failed due to ${(errors || [status]).join()}`
      );
    }
  }
}
