import { IConnector } from "@unifiprotocol/core-sdk";
import { Currency } from "@unifiprotocol/utils";
import { Decimals } from "../Contracts/ERC20/Decimals";
import { Name } from "../Contracts/ERC20/Name";
import { Symbol } from "../Contracts/ERC20/Symbol";
import { ABI } from "../Contracts/Pair";
import { Pair } from "../Contracts/Pair/Pair";
import { ILiquidityPool } from "../Entities/LiquidityPool";
import { getStorageKey, setStorageKey } from "../Utils/ChainStorage";

export class ContractFetcher {
  public static async getToken(
    tokenAddress: string,
    connector: IConnector
  ): Promise<Currency> {
    const cachedToken = getStorageKey(
      connector.config.blockchain,
      tokenAddress
    );
    if (cachedToken) {
      try {
        const currency: Currency = JSON.parse(cachedToken);
        return new Currency(
          tokenAddress,
          currency.decimals,
          currency.symbol,
          currency.name
        );
      } catch (err) {}
    }
    const calls = [
      new Decimals({
        tokenAddress,
      }),
      new Symbol({
        tokenAddress,
      }),
      new Name({
        tokenAddress,
      }),
    ];
    await connector.adapter!.adapter.initializeContract(tokenAddress, ABI);
    const [decimals, symbol, name] = await connector.adapter!.multicall.execute(
      calls
    );
    const currency = new Currency(
      tokenAddress,
      decimals.value,
      symbol.value,
      name.value
    );
    setStorageKey(
      connector.config.blockchain,
      tokenAddress,
      JSON.stringify(currency)
    );
    return currency;
  }

  public static async getLiquidityPool(
    poolAddress: string,
    connector: IConnector
  ): Promise<ILiquidityPool> {
    const poolToken = await ContractFetcher.getToken(poolAddress, connector);
    const pairInstance = new Pair(connector, poolAddress);
    const [token0Addr, token1Addr] = await Promise.all([
      pairInstance.token0(),
      pairInstance.token1(),
    ]);
    const [token0, token1] = await Promise.all([
      ContractFetcher.getToken(token0Addr, connector),
      ContractFetcher.getToken(token1Addr, connector),
    ]);
    const [reserves0, reserves1] = await pairInstance.getActualReserves();
    const totalSupply = await pairInstance.getTotalSupply();
    const multiplier = await pairInstance.getMultiplier();
    return {
      poolAddress,
      poolToken,
      token0,
      token1,
      reserves0,
      reserves1,
      multiplier,
      totalSupply,
    };
  }
}
