import { ShinyHeader } from "@unifiprotocol/uikit";
import { useAdapter } from "../../Adapter";
import { Hero } from "../../Components/Hero";
import { AutopoolsTable } from "./Components/AutopoolsTable";
import { AutopoolsWrapper, DescriptionText } from "./styles";

export const Autopools = () => {
  const { adapter } = useAdapter();

  return (
    <AutopoolsWrapper>
      <Hero>
        <ShinyHeader>DARBi Pro</ShinyHeader>
        <DescriptionText>
          <p>
            Welcome to the DARBi alpha version. First, connect your wallet to
            begin to add or remove liquidity.
            <br />
            If you don't see any Liquidity Pools, please contact the Unifi
            support team.
          </p>
        </DescriptionText>
      </Hero>
      {adapter?.getAddress() && adapter?.getAddress().length > 0 ? (
        <AutopoolsTable />
      ) : (
        <div style={{ textAlign: "center" }}>
          <h3>Please connect a Wallet</h3>
        </div>
      )}
    </AutopoolsWrapper>
  );
};
