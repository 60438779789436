import { ContractUseCase } from "@unifiprotocol/core-sdk";
import { MultiRouterContractMethod } from "./";

export interface RemoveLiquidityEthContractParams {
  tokenAddress: string;
  liquidity: string;
  amountTokenMin: string;
  amountEthMin: string;
  to: string;
  deadline: string;
  router: string;
  pairAddress: string;
}

export class RemoveLiquidityEth extends ContractUseCase<
  MultiRouterContractMethod,
  RemoveLiquidityEthContractParams
> {
  constructor(params: RemoveLiquidityEthContractParams) {
    super(
      params.router,
      MultiRouterContractMethod.RemoveLiquidityEth,
      params,
      true
    );
  }

  getArgs() {
    return [
      this.params.tokenAddress,
      this.params.liquidity,
      this.params.amountTokenMin,
      this.params.amountEthMin,
      this.params.to,
      this.params.deadline,
      this.params.pairAddress,
    ];
  }
}
