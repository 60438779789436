import { ShinyHeader } from "@unifiprotocol/uikit";
import React from "react";
import { useAdapter } from "../Adapter";
import { Config } from "../Config";
import { Hero } from "./Hero";
import styled from "styled-components";

const BodyWrapper = styled.div`
  margin-top: 1rem;
  width: 100vw;
  display: flex;
  justify-content: center;
  position: relative;

  @media (max-width: 576px) {
    padding: 0 1.25rem;
  }

  > div {
    max-width: 992px;
  }
`;

export const Body: React.FC = ({ children }) => {
  const { blockchainConfig } = useAdapter();

  if (!Config[blockchainConfig.blockchain]) {
    return (
      <BodyWrapper>
        <Hero>
          <ShinyHeader>Blockchain not supported</ShinyHeader>
          Please contact Unifi Protocol support.
        </Hero>
      </BodyWrapper>
    );
  }

  return (
    <BodyWrapper>
      <div>{children}</div>
    </BodyWrapper>
  );
};
