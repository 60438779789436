import { ColumnBody, RowColumnWrapper, TableRow } from "@unifiprotocol/uikit";
import styled from "styled-components";

export const AutopoolCardWrapper = styled.tbody`
  &:before {
    content: "";
    display: block;
    /* height: 1.5rem; */
  }
  ${(props) => props.theme.mediaQueries.xs} {
    &:before {
      content: "";
      display: block;
      height: 0.5rem;
    }
  }
`;

export const Column = styled(RowColumnWrapper)`
  flex-direction: column;
  padding: 0.5rem;
  flex: 1 1 8rem;
`;

export const ColumnMain = styled(RowColumnWrapper)`
  width: 15rem;
  vertical-align: middle !important;
`;

export const PaddedTableRow = styled(TableRow)`
  padding: 0 1rem 0;
`;

export const AutopoolMainInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BoldText = styled.b`
  font-weight: 600;
  font-size: 1rem;
`;

export const LightText = styled.b`
  font-size: 0.8rem;
  font-weight: 300;
  opacity: 0.7;
`;

export const ColumnTitle = styled.div`
  color: ${({ theme }) => theme.primary};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  button {
    margin: 0.1rem 0;
    width: 100%;
  }
`;

export const SpaceEvenlyColumnBody = styled(ColumnBody)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: max-content;
`;

export const Badge = styled.span`
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 70%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: ${(p) => p.theme.borderRadius};
  cursor: default;
`;

export const MultiplierBadge = styled(Badge)`
  background: ${(p) => p.theme.primary};
  color: #000;
`;
