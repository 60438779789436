import { ModalBody } from "@unifiprotocol/uikit";
import styled from "styled-components";

export const SpaceBetweenModalBody = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  justify-content: space-betweeen;
`;

export const IconWrapper = styled.div`
  width: calc(100%-2rem);
  text-align: center;
  color: ${({ theme }) => theme.primary};
  font-size: 1.5rem;
  margin: 1rem;
`;
