import {
  CgChevronDoubleRight,
  CgSwapVertical,
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
  PrimaryButton,
  TokenInput,
  useModal,
} from "@unifiprotocol/uikit";
import { BN } from "@unifiprotocol/utils";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAdapter } from "../../../../Adapter";
import { ApprovalButton } from "../../../../Components/ApprovalButton";
import {
  InfoNotification,
  SuccessNotification,
  FailNotification,
} from "../../../../Components/Notifications";
import {
  ILiquidityPool,
  LiquidityPool,
} from "../../../../Entities/LiquidityPool";
import { LiquidityService } from "../../../../Services/LiquidityService";
import { ButtonsWrapper, PrimaryIconWrapper } from "../Styles";
import {
  TransferDescription,
  TransferRatePreview,
  TransferResultPreview,
  AmplifiedTokenAmount,
} from "./Styles";

const RemoveLiquidityComponent: FC<{
  pool?: ILiquidityPool;
  close: () => void;
}> = ({ pool, close }) => {
  const { adapter, eventBus, getBalanceByCurrency } = useAdapter();
  const [amount, setAmount] = useState("0");
  const [allowed, setAllowed] = useState(false);

  const liquidityPool = useMemo(() => {
    if (!pool) return undefined;
    return new LiquidityPool(pool);
  }, [pool]);

  const userBalance = useMemo(() => {
    if (!liquidityPool) return "0";
    return getBalanceByCurrency(liquidityPool.poolToken, true).balance;
  }, [getBalanceByCurrency, liquidityPool]);

  const onSubmit = useCallback(() => {
    if (!pool || !liquidityPool || !adapter) return;
    if (!adapter || !pool) return;
    const liquidityService = new LiquidityService();
    return liquidityService
      .removeLiquidity({
        removeAmount: pool.poolToken.toPrecision(amount),
        liquidityPool,
        adapter,
      })
      .then((res) => {
        console.log("~~~~", res);
        if (res.success) {
          eventBus.emit(
            InfoNotification(
              `Removing ${liquidityPool.token0.symbol}-${liquidityPool.token1.symbol} liquidity`
            )
          );
          adapter.waitForTransaction(res.hash).then(() => {
            res.success
              ? SuccessNotification(
                  `${liquidityPool.token0.symbol}-${liquidityPool.token1.symbol} liquidity added succesfully`
                )
              : FailNotification(
                  `Adding ${liquidityPool.token0.symbol}-${liquidityPool.token1.symbol} liquidity has failed`
                );
          });
          close();
        } else {
          eventBus.emit(
            FailNotification(
              `Removing ${liquidityPool.token0.symbol}-${liquidityPool.token1.symbol} liquidity has failed`
            )
          );
        }
      });
  }, [adapter, amount, close, eventBus, liquidityPool, pool]);

  const rate0 = useMemo(
    () =>
      liquidityPool &&
      BN(liquidityPool.reserves0).div(liquidityPool.totalSupply).dp(4),
    [liquidityPool]
  );

  const rate1 = useMemo(
    () =>
      liquidityPool &&
      BN(liquidityPool.reserves1).div(liquidityPool.totalSupply).dp(4),
    [liquidityPool]
  );

  if (!pool || !liquidityPool) {
    return <></>;
  }

  return (
    <Modal>
      <ModalHeader>
        <span>Remove liquidity</span>
        <ModalClose onClick={close} />
      </ModalHeader>
      <ModalBody>
        <TokenInput
          token={pool.poolToken}
          label={pool.poolToken.symbol}
          balance={userBalance}
          balanceLabel={pool.poolToken.symbol}
          amount={amount}
          onAmountChange={setAmount}
        ></TokenInput>
        <TransferDescription>
          <CgSwapVertical size={40} />
          You will receive
        </TransferDescription>
        <TransferResultPreview>
          <AmplifiedTokenAmount
            token={pool.token0}
            amount={rate0?.multipliedBy(amount).toFixed() || "0"}
          ></AmplifiedTokenAmount>
          <AmplifiedTokenAmount
            token={pool.token1}
            amount={rate1?.multipliedBy(amount).toFixed() || "0"}
          ></AmplifiedTokenAmount>
        </TransferResultPreview>
        <TransferRatePreview>
          <span>Current rate</span>
          <span className="rates">
            <span>
              {rate0?.toFixed()} {pool.token1.symbol} for{" "}
              {pool.poolToken.symbol}
            </span>
            <span>
              {rate1?.toFixed()} {pool.token0.symbol} for{" "}
              {pool.poolToken.symbol}
            </span>
          </span>
        </TransferRatePreview>
        <ButtonsWrapper>
          <ApprovalButton
            onApprove={setAllowed}
            tokens={[
              {
                allowance: pool.poolToken.toPrecision(amount),
                token: pool.poolToken,
              },
            ]}
          />
          <PrimaryIconWrapper>
            <CgChevronDoubleRight size={30}></CgChevronDoubleRight>
          </PrimaryIconWrapper>
          <PrimaryButton onClick={onSubmit} disabled={!allowed}>
            Submit
          </PrimaryButton>
        </ButtonsWrapper>
      </ModalBody>
    </Modal>
  );
};

export const RemoveLiquidityController = ({
  modalProps,
  onClose,
  isOpen,
}: {
  modalProps: { pool?: ILiquidityPool };
  onClose: () => void;
  isOpen: boolean;
}) => {
  const [open, close] = useModal({
    component: RemoveLiquidityComponent,
    props: modalProps,
    options: {
      onClose: () => {
        isOpen && onClose();
      },
      disableBackdropClick: true,
    },
  });

  useEffect(() => {
    isOpen && modalProps.pool ? open() : close();
  }, [open, close, isOpen, modalProps.pool]);

  return <></>;
};
