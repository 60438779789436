import { TokenAmount } from "@unifiprotocol/uikit";
import styled from "styled-components";

export const TransferDescription = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.primary};
  margin: 0.5rem;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
`;

export const TransferResultPreview = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
  overflow: hidden;
  > * {
    flex: 1 1 45%;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`;

export const AmplifiedTokenAmount = styled(TokenAmount)`
  justify-content: center !important;
  & *:last-child {
    flex: 10;
  }
`;

export const TransferRatePreview = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding: 0.2rem 1rem;

  .rates {
    display: flex;
    flex-direction: column;
    text-align: right;
  }
`;
