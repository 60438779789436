import { IconType } from "@unifiprotocol/uikit";
import { Blockchains } from "@unifiprotocol/utils";

export interface IAutopoolInfo {
  name: string;
  address: string;
  blockchain: Blockchains;
  arbPool: string;
  flashbot: string;
  rewardToken: string;
  loggers: string[];
  threshold: number;
}

type Input = {
  key: string;
  type: string;
  displayName: string;
  regexValidator?: RegExp;
};

export interface Logger {
  key: string;
  name: string;
  icon: IconType;
  inputs: Input[];
}

export enum LogLevel {
  // Trace = 0,
  // Debug = 1,
  // Info = 2,
  // Warn = 3,
  // Error = 4,
  Transaction = 5,
}
