import { ContractUseCase } from "@unifiprotocol/core-sdk";
import { FactoryContractMethod } from ".";

export interface AllPairsParams {
  factoryAddress: string;
  index: number;
}

export class AllPairs extends ContractUseCase<
  FactoryContractMethod,
  AllPairsParams,
  string
> {
  constructor(params: AllPairsParams) {
    super(params.factoryAddress, FactoryContractMethod.AllPairs, params, false);
  }

  getArgs() {
    return [this.params.index];
  }
}
