import { FC } from "react";
import styled from "styled-components";

const PairTokenLogoWrapper = styled.span`
  > img {
    width: auto;
    height: 3rem;
  }
`;

export const PairTokenLogo: FC = ({ children }) => (
  <PairTokenLogoWrapper>{children}</PairTokenLogoWrapper>
);
