import { TransactionStatus } from "@unifiprotocol/core-sdk";
import { PrimaryButton } from "@unifiprotocol/uikit";
import { BN, Currency } from "@unifiprotocol/utils";
import { constants } from "ethers";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAdapter } from "../Adapter";
import { useConfig } from "../Config";
import { ERC20 } from "../Contracts/ERC20/ERC20";
import Clocks from "../Services/Clocks";
import {
  FailNotification,
  InfoNotification,
  SuccessNotification,
} from "./Notifications";

export const ApprovalButton: React.FC<{
  onApprove?: (isApproved: boolean) => void;
  tokens: { allowance: string; token: Currency }[];
}> = ({ tokens, onApprove }) => {
  const { connection, adapter, eventBus } = useAdapter();
  const { config } = useConfig();
  const [approving, setApproving] = useState(false);
  const [allowance, setAllowance] = useState<{ [tokenAddr: string]: string }>(
    {}
  );

  const isEnoughAllowance = useMemo(
    () => tokens.every((t) => BN(allowance[t.token.address]).gte(t.allowance)),
    [allowance, tokens]
  );

  const checkAllowance = useCallback(
    (addr: string) => {
      const erc20 = new ERC20(connection, addr);
      erc20
        .allowance(adapter?.getAddress() ?? "", config!.router)
        .then((allowance) => {
          setAllowance((st) => ({ ...st, [addr]: allowance }));
        });
    },
    [adapter, config, connection]
  );

  const approve = useCallback(async () => {
    for (const { token } of tokens) {
      const erc20 = new ERC20(connection, token.address);
      await erc20
        .approve(config!.router, constants.MaxUint256.toString())
        .then(({ success, hash }) => {
          if (success === false) {
            eventBus.emit(FailNotification("Approval execution failed."));
            setApproving(false);
          } else {
            eventBus.emit(InfoNotification(`Approving ${token.symbol}.`));
            connection.adapter?.adapter.waitForTransaction(hash).then((res) => {
              if (res === TransactionStatus.Success) {
                eventBus.emit(
                  SuccessNotification(`${token.symbol} approved successfully.`)
                );
              } else {
                eventBus.emit(FailNotification("Approval execution failed."));
              }
            });
          }
        });
    }
  }, [config, connection, eventBus, tokens]);

  const onApproveClick = useCallback(async () => {
    try {
      if (approving) return;
      setApproving(true);
      await approve();
    } catch (err) {
      console.log("🚀 ~ file: index.tsx ~ line 45 ~ onApproveClick ~ err", err);
      setApproving(false);
    }
  }, [approve, approving]);

  useEffect(() => {
    tokens.forEach((t) => checkAllowance(t.token.address));
  }, [checkAllowance, tokens]);

  useEffect(() => {
    if (isEnoughAllowance) return;
    const fn = () => tokens.forEach((t) => checkAllowance(t.token.address));
    Clocks.on("FIVE_SECONDS", fn);
    return () => {
      Clocks.off("FIVE_SECONDS", fn);
    };
  }, [checkAllowance, tokens, isEnoughAllowance]);

  useEffect(() => {
    if (isEnoughAllowance) setApproving(false);
  }, [allowance, isEnoughAllowance, tokens]);

  const buttonText = useMemo(() => {
    if (isEnoughAllowance) {
      return "Approved";
    }
    if (approving) return "Approving";
    return "Approve";
  }, [approving, isEnoughAllowance]);

  useEffect(() => {
    if (onApprove) {
      if (buttonText === "Approved") {
        onApprove(true);
      } else {
        onApprove(false);
      }
    }
  }, [buttonText, onApprove]);

  return (
    <PrimaryButton disabled={isEnoughAllowance} onClick={onApproveClick}>
      {buttonText}
    </PrimaryButton>
  );
};
