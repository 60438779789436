import { IConnector } from "@unifiprotocol/core-sdk";
import { BN } from "@unifiprotocol/utils";
import { ABI, FactoryContractMethod } from ".";
import { AllPairs } from "./AllPairs";

type MethodOptions = {
  adapter?: IConnector;
};

export class Factory {
  private _initPromise: Promise<any>;
  constructor(private connector: IConnector, private factoryAddress: string) {
    this._initPromise = this.initializeContract();
  }

  private async initializeContract() {
    await this.connector.connect();
    await this.connector.adapter!.adapter.initializeContract(
      this.factoryAddress,
      ABI
    );
  }

  public async allPairs(
    index: number,
    options: MethodOptions = {}
  ): Promise<string> {
    await this._initPromise;
    const response = await this.connector.adapter!.adapter.execute(
      this.factoryAddress,
      FactoryContractMethod.AllPairs,
      {},
      false
    );
    return response.value;
  }

  public async allPairsBatch(
    indexes: number[],
    options: MethodOptions = {}
  ): Promise<string[]> {
    await this._initPromise;
    const response = await this.connector.adapter!.multicall.execute(
      indexes.map(
        (index) => new AllPairs({ factoryAddress: this.factoryAddress, index })
      )
    );
    return response.map((res) => res.value);
  }

  public async allPairsLength(options: MethodOptions = {}): Promise<number> {
    await this._initPromise;
    const response = await this.connector.adapter!.adapter.execute(
      this.factoryAddress,
      FactoryContractMethod.AllPairsLength,
      {},
      false
    );
    return BN(response.value).toNumber();
  }

  public async depositorWhitelistedAddress(
    userAddress: string,
    poolAddress: string,
    options: MethodOptions = {}
  ): Promise<boolean> {
    await this._initPromise;
    const response = await this.connector.adapter!.adapter.execute(
      this.factoryAddress,
      FactoryContractMethod.DepositorWhitelistedAddress,
      { args: [userAddress, poolAddress] },
      false
    );
    return response.value === "true";
  }
}
