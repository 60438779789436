import { Themes, UnifiThemeProvider } from "@unifiprotocol/uikit";
import { ShellWrappedComp } from "@unifiprotocol/shell";
import { AppTheme } from "./Styles/Theme";
import { Autopools } from "./Views/Autopools";
import { TokenLogoResolvers } from "@unifiprotocol/utils";
import { AdapterContext } from "./Adapter";
import { Body } from "./Components/Body";
import { useEffect } from "react";
import Clocks from "./Services/Clocks";
import { Pool } from "./Pool/Component";
import { Summary } from "./Views/Summary";
import { Route, Routes } from "react-router-dom";

const CombinedTheme = {
  ...Themes.Dark,
  AppTheme,
};

export const App: ShellWrappedComp = ({ connection, eventBus, balances }) => {
  useEffect(() => {
    Clocks.start();
  }, []);

  return (
    <AdapterContext.Provider value={{ connection, eventBus, balances }}>
      <UnifiThemeProvider
        theme={CombinedTheme}
        options={{
          tokenLogoResolver: TokenLogoResolvers[connection.config.blockchain],
        }}
      >
        <Pool />
        <Body>
          <Routes>
            <Route path="/debug" element={<Summary />} />
            <Route path="/" element={<Autopools />} />
          </Routes>
        </Body>
      </UnifiThemeProvider>
    </AdapterContext.Provider>
  );
};
