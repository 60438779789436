import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { render } from "react-dom";
import { RecoilRoot } from "recoil";
import { Shell } from "@unifiprotocol/shell";

const container = document.getElementById("root") as HTMLElement;

render(
  <RecoilRoot>
    <Shell Wrapped={App} />
  </RecoilRoot>,
  container
);

reportWebVitals();
