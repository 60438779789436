import {
  CgMathMinus,
  CgMathPlus,
  ColumnTitle,
  FiSettings,
  SecondaryButton,
  TableRow,
  TokenLogo,
} from "@unifiprotocol/uikit";
import { FC, useMemo } from "react";
import styled from "styled-components";
import { LightTooltip } from "../../../../Components/LigthTooltip";
import {
  ILiquidityPool,
  LiquidityPool,
} from "../../../../Entities/LiquidityPool";
import { ActionType } from "../AutopoolsTable";
import { PairTokenLogo } from "../PairTokenLogo";
import {
  AutopoolCardWrapper,
  AutopoolMainInfo,
  MultiplierBadge,
  BoldText,
  ButtonsWrapper,
  Column,
  ColumnMain,
  LightText,
  SpaceEvenlyColumnBody,
} from "./Styles";

const VerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

export const AutopoolCard: FC<{
  onActionClick: (autopool: ILiquidityPool, action: ActionType) => any;
  autopool: ILiquidityPool;
}> = ({ onActionClick, autopool }) => {
  const liquidityPool = useMemo(
    () => new LiquidityPool({ ...autopool }),
    [autopool]
  );

  return (
    <AutopoolCardWrapper>
      <TableRow>
        <ColumnMain>
          <VerticalFlex>
            <PairTokenLogo>
              <TokenLogo
                token={{
                  address: autopool.token0.address,
                  symbol: autopool.token0.symbol,
                }}
              />
              <TokenLogo
                token={{
                  address: autopool.token1.address,
                  symbol: autopool.token1.symbol,
                }}
              />
            </PairTokenLogo>
            <AutopoolMainInfo>
              <BoldText>
                {autopool.token0.symbol}-{autopool.token1.symbol}{" "}
                <LightTooltip
                  placement="bottom"
                  title="Autopools multiplier"
                  event="onClick"
                >
                  <MultiplierBadge>{autopool.multiplier}x</MultiplierBadge>
                </LightTooltip>
              </BoldText>
              <span>
                <BoldText>{liquidityPool.getPrice()}</BoldText>{" "}
                <LightText> {autopool.token1.symbol}</LightText>
              </span>
            </AutopoolMainInfo>
          </VerticalFlex>
        </ColumnMain>
        <Column>
          <ColumnTitle align="right">Last 24H</ColumnTitle>
          <SpaceEvenlyColumnBody align="right">
            <div>
              <BoldText>0%</BoldText>
              <LightText> APY</LightText>
            </div>
            <div>
              <BoldText>0%</BoldText>
              <LightText> APR</LightText>
            </div>
          </SpaceEvenlyColumnBody>
        </Column>
        <Column>
          <ColumnTitle align="right">Liquidity</ColumnTitle>
          <SpaceEvenlyColumnBody align="right">
            <div>
              <BoldText>
                {liquidityPool.token0.toFactorized(liquidityPool.reserves0, 4)}
              </BoldText>
              <LightText> {liquidityPool.token0.symbol}</LightText>
            </div>
            <div>
              <BoldText>
                {liquidityPool.token1.toFactorized(liquidityPool.reserves1, 4)}
              </BoldText>
              <LightText> {liquidityPool.token1.symbol}</LightText>
            </div>
          </SpaceEvenlyColumnBody>
        </Column>
        <Column>
          <ColumnTitle align="right">Rewards</ColumnTitle>
          <SpaceEvenlyColumnBody align="right">
            <div>
              <BoldText>0</BoldText>
              <LightText> {autopool.token1.symbol}</LightText>
            </div>
          </SpaceEvenlyColumnBody>
        </Column>
        <ColumnMain>
          <ButtonsWrapper>
            <SecondaryButton
              size={"md"}
              variant={"fill"}
              onClick={() => onActionClick(autopool, "addLiquidity")}
            >
              <CgMathPlus size={20} />
              Add liquidity
            </SecondaryButton>
            <SecondaryButton
              size={"md"}
              variant={"fill"}
              onClick={() => onActionClick(autopool, "removeLiquidity")}
            >
              <CgMathMinus size={20} /> Remove liquidity
            </SecondaryButton>
            <SecondaryButton
              size={"md"}
              variant={"fill"}
              onClick={() => onActionClick(autopool, "settings")}
            >
              <FiSettings size={20} /> Settings
            </SecondaryButton>
          </ButtonsWrapper>
        </ColumnMain>
      </TableRow>
    </AutopoolCardWrapper>
  );
};
