import { ContractUseCase } from "@unifiprotocol/core-sdk";
import { MultiRouterContractMethod } from "./";
import { toHex } from "@unifiprotocol/utils";

export interface AddLiquidityEthContractParams {
  ethAmountMin: string;
  ethAmountDesired: string;
  token0Address: string;
  token0AmountDesired: string;
  token0AmountMin: string;
  to: string;
  deadline: string;
  router: string;
  pairAddress: string; // No-standard
}

export class AddLiquidityEth extends ContractUseCase<
  MultiRouterContractMethod,
  AddLiquidityEthContractParams
> {
  constructor(params: AddLiquidityEthContractParams) {
    super(
      params.router,
      MultiRouterContractMethod.AddLiquidityEth,
      params,
      true
    );
  }

  getCallValue(): string {
    return toHex(this.params.ethAmountDesired);
  }

  getArgs() {
    return [
      this.params.token0Address,
      toHex(this.params.token0AmountDesired),
      toHex(this.params.token0AmountMin),
      toHex(this.params.ethAmountMin),
      this.params.to,
      toHex(this.params.deadline),
      this.params.pairAddress,
    ];
  }
}
