import { FaSlack } from "@unifiprotocol/uikit";
import { Logger } from "../Types";

export const Loggers: Logger[] = [
  {
    name: "Slack Logger",
    key: "slack",
    icon: FaSlack,
    inputs: [
      {
        key: "webhook",
        type: "text",
        displayName: "Webhook",
        regexValidator: /.+/,
      },
    ],
  },
  // {
  //   name: "GSheet Logger",
  //   key: "gsheet",
  //   icon: FaGoogleDrive,
  //   inputs: [
  //     {
  //       key: "sheetId",
  //       type: "text",
  //       displayName: "Sheet Id",
  //       regexValidator: /.+/,
  //     },
  //     {
  //       key: "tab",
  //       type: "text",
  //       displayName: "Sheet Tab",
  //       regexValidator: /.+/,
  //     },
  //   ],
  // },
];
