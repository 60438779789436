import {
  IoAddCircleOutline,
  MdEdit,
  MdRemoveCircleOutline,
  Modal,
  ModalBody,
  ModalHeader,
  SelectMenu,
  useModal,
} from "@unifiprotocol/uikit";
import {
  createElement,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { Logger, LogLevel } from "../../../../../Types";
import {
  CenteredContent,
  InputPlaceholder,
  LighterInput,
  MarginedPrimaryButton,
} from "../Styles";

type LoggerConfigParams = {
  logger: Logger;
  onSave: (...args: any) => any;
  isSetup: boolean;
};

const LoggerLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 2rem;
  border-radius: 100%;
  flex: 1;
`;

const LoggerConfigWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  background: ${({ theme }) => theme.bgAlt1};
  border: 1px ${({ theme }) => theme.primary} solid;
  border-radius: 1rem;
  margin-bottom: 1rem;
`;

const LoggerName = styled.div`
  flex: 4;
  font-weight: 1rem;
`;

const ActionIconWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.5rem;
  &:hover {
    filter: brightness(75%);
    cursor: pointer;
  }
  span {
    margin-right: 1rem;
  }
  border-radius: 100%;
`;

export const LoggerConfig: FC<LoggerConfigParams> = ({
  isSetup,
  logger,
  onSave,
}) => {
  const iconComponent = useMemo(
    () => createElement(logger.icon, {}),
    [logger.icon]
  );

  const [open, setOpen] = useState(false);

  const modalProps = useMemo(
    () => ({ logger, onSave, onClose: () => setOpen(false) }),
    [logger, onSave, setOpen]
  );

  return (
    <LoggerConfigWrapper>
      <LoggerConfigModalController
        onClose={() => setOpen(false)}
        isOpen={open}
        modalProps={modalProps}
      />
      <LoggerLogoWrapper>{iconComponent}</LoggerLogoWrapper>
      <LoggerName>{logger.name}</LoggerName>
      {isSetup ? (
        <>
          <ActionIconWrapper onClick={() => setOpen(true)}>
            <MdEdit />
          </ActionIconWrapper>
          <ActionIconWrapper onClick={() => onSave(null)}>
            <MdRemoveCircleOutline />
          </ActionIconWrapper>
        </>
      ) : (
        <ActionIconWrapper onClick={() => setOpen(true)}>
          <IoAddCircleOutline />
        </ActionIconWrapper>
      )}
    </LoggerConfigWrapper>
  );
};

type LoggerConfigModalProps = {
  logger: Logger;
  values?: Record<string, number | string>;
  onClose: (...args: any[]) => any;
  onSave: (...args: any[]) => any;
};

const LoggerConfigModal: FC<LoggerConfigModalProps> = ({
  logger,
  values = {},
  onClose,
  onSave,
}) => {
  const [inputValues, setInputValues] = useState(values);
  const [level, setLevel] = useState<number>();

  const setInputValue = useCallback(
    (key: string, value: string | number) => {
      setInputValues((st) => ({ ...st, [key]: value }));
    },
    [setInputValues]
  );

  const updateLevel = useCallback(
    (level: number) => {
      setLevel(level);
      setInputValue("level", level);
    },
    [setLevel, setInputValue]
  );

  const validInputs = useMemo(
    () =>
      logger.inputs
        .map(
          ({ key, regexValidator }) =>
            !regexValidator ||
            String(inputValues[key] ?? "").match(regexValidator) != null
        )
        .every((e) => e === true) && level !== undefined,
    [inputValues, logger.inputs, level]
  );

  const levels = useMemo(
    () =>
      Object.values(LogLevel)
        .filter((e) => isNaN(Number(e)))
        .map((level: any) => ({
          label: level,
          value: LogLevel[level],
        })),
    []
  );

  return (
    <Modal>
      <ModalHeader>{logger.name} configuration</ModalHeader>
      <ModalBody>
        <>
          {logger.inputs.map((input, i) => (
            <>
              <InputPlaceholder key={2 * i}>
                {input.displayName}
              </InputPlaceholder>
              <LighterInput
                key={2 * i + 1}
                type={input.type}
                placeholder={input.displayName}
                value={inputValues[input.key]}
                onChange={(ev) => setInputValue(input.key, ev.target.value)}
              />
            </>
          ))}
          <InputPlaceholder>Level</InputPlaceholder>
          <SelectMenu
            options={levels}
            onChange={(ev: any) => updateLevel(ev.value)}
          />
        </>
        <CenteredContent>
          <MarginedPrimaryButton
            disabled={!validInputs}
            onClick={() => {
              onSave(inputValues);
              onClose();
            }}
          >
            Save changes
          </MarginedPrimaryButton>

          <MarginedPrimaryButton onClick={onClose}>
            Cancel
          </MarginedPrimaryButton>
        </CenteredContent>
      </ModalBody>
    </Modal>
  );
};

export const LoggerConfigModalController = ({
  modalProps,
  onClose,
  isOpen,
}: {
  modalProps: LoggerConfigModalProps;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const [open, close] = useModal({
    component: LoggerConfigModal,
    props: modalProps,
    options: { onClose, disableBackdropClick: true },
  });

  useEffect(() => {
    isOpen ? open() : close();
  }, [open, close, isOpen]);

  return <></>;
};
