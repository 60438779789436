import {
  AiOutlinePlus,
  CgChevronDoubleRight,
  Modal,
  ModalBody,
  ModalClose,
  ModalHeader,
  PrimaryButton,
  TokenInput,
  useModal,
} from "@unifiprotocol/uikit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAdapter } from "../../../../Adapter";
import {
  ILiquidityPool,
  LiquidityPool,
} from "../../../../Entities/LiquidityPool";
import { LiquidityService } from "../../../../Services/LiquidityService";
import { ApprovalButton } from "../../../../Components/ApprovalButton";
import { ButtonsWrapper, PrimaryIconWrapper } from "../Styles";
// import { CurrentPosition } from "./CurrentPosition";
import { PoolStats } from "./PoolStatsWrapper";
import { IconWrapper } from "./Styles";
import { BN, ifNanThen } from "@unifiprotocol/utils";
import {
  FailNotification,
  InfoNotification,
  SuccessNotification,
} from "../../../../Components/Notifications";
import { TransactionStatus } from "@unifiprotocol/core-sdk";

const AddLiquidityComponent: React.FC<{
  pool?: ILiquidityPool;
  close: () => void;
}> = ({ pool, close }) => {
  const [amount0, setAmount0] = useState("0");
  const [amount1, setAmount1] = useState("0");
  const [allowed, setAllowed] = useState(false);
  const { adapter, blockchainConfig, eventBus, getBalanceByCurrency } =
    useAdapter();

  const onSubmit = useCallback(() => {
    if (!adapter || !pool) return;
    const liquidityService = new LiquidityService();
    const liquidityPool = new LiquidityPool({ ...pool });
    return liquidityService
      .addLiquidity({
        token0AmountDesired: pool.token0.toPrecision(amount0),
        token1AmountDesired: pool.token1.toPrecision(amount1),
        liquidityPool,
        adapter,
      })
      .then((res) => {
        console.log("~~~~", res);
        if (res.success) {
          eventBus.emit(
            InfoNotification(
              `Adding ${liquidityPool.token0.symbol}-${liquidityPool.token1.symbol} liquidity`
            )
          );
          adapter.waitForTransaction(res.hash).then((status) => {
            eventBus.emit(
              status === TransactionStatus.Success
                ? SuccessNotification(
                    `${liquidityPool.token0.symbol}-${liquidityPool.token1.symbol} liquidity added succesfully`
                  )
                : FailNotification(
                    `Adding ${liquidityPool.token0.symbol}-${liquidityPool.token1.symbol} liquidity has failed`
                  )
            );
          });
          close();
        } else {
          eventBus.emit(
            FailNotification(
              `Adding ${liquidityPool.token0.symbol}-${liquidityPool.token1.symbol} liquidity has failed`
            )
          );
        }
      });
  }, [adapter, amount0, amount1, close, eventBus, pool]);

  const token0 = useMemo(() => {
    if (!pool) return undefined;
    return pool.token0.equals(blockchainConfig.wrappedToken)
      ? blockchainConfig.nativeToken
      : pool.token0;
  }, [blockchainConfig, pool]);

  const token1 = useMemo(() => {
    if (!pool) return undefined;
    return pool.token1.equals(blockchainConfig.wrappedToken)
      ? blockchainConfig.nativeToken
      : pool.token1;
  }, [blockchainConfig, pool]);

  const approvals = useMemo(() => {
    if (!token0 || !token1) return [];
    return [
      { token: token0, allowance: token0.toPrecision(amount0) },
      { token: token1, allowance: token1.toPrecision(amount1) },
    ];
  }, [amount0, amount1, token0, token1]);

  const onAmountChange = useCallback(
    (amount: string, side: 0 | 1) => {
      if (!pool || !token0 || !token1) return;
      const setter = side === 0 ? setAmount0 : setAmount1;
      const againstSetter = side === 0 ? setAmount1 : setAmount0;
      const liquidityPool = new LiquidityPool(pool);
      const againstPrice = liquidityPool.getAgainstPrice();
      const price = liquidityPool.getPrice();
      if (side === 0) {
        setter(amount);
        if (!liquidityPool.hasLiquidity()) return;
        againstSetter(ifNanThen(BN(amount).multipliedBy(price).toFixed(), "0"));
      } else {
        setter(amount);
        if (!liquidityPool.hasLiquidity()) return;
        againstSetter(
          ifNanThen(BN(amount).multipliedBy(againstPrice).toFixed(), "0")
        );
      }
    },
    [pool, token0, token1]
  );

  if (!pool || !token0 || !token1) return <></>;

  return (
    <Modal>
      <ModalHeader>
        <span>Add liquidity</span>
        <ModalClose onClick={close} />
      </ModalHeader>
      <ModalBody>
        <PoolStats autopool={pool} />
        <TokenInput
          token={token0}
          label={""}
          balance={getBalanceByCurrency(token0, true).balance}
          balanceLabel={"Balance"}
          amount={amount0}
          onAmountChange={(amt) => onAmountChange(amt, 0)}
        ></TokenInput>
        <IconWrapper>
          <AiOutlinePlus></AiOutlinePlus>
        </IconWrapper>
        <TokenInput
          token={token1}
          label={""}
          balance={getBalanceByCurrency(token1, true).balance}
          balanceLabel={"Balance"}
          amount={amount1}
          onAmountChange={(amt) => onAmountChange(amt, 1)}
        ></TokenInput>
        <ButtonsWrapper>
          <ApprovalButton tokens={approvals} onApprove={setAllowed} />
          <PrimaryIconWrapper>
            <CgChevronDoubleRight size={30}></CgChevronDoubleRight>
          </PrimaryIconWrapper>
          <PrimaryButton disabled={!allowed} onClick={onSubmit}>
            Submit
          </PrimaryButton>
        </ButtonsWrapper>
        {/* <CurrentPosition></CurrentPosition> */}
      </ModalBody>
    </Modal>
  );
};

export const AddLiquidityController = ({
  modalProps,
  onClose,
  isOpen,
}: {
  modalProps: { pool?: ILiquidityPool };
  onClose: () => void;
  isOpen: boolean;
}) => {
  const [open, close] = useModal({
    component: AddLiquidityComponent,
    props: modalProps,
    options: {
      onClose: () => {
        isOpen && onClose();
      },
      disableBackdropClick: true,
    },
  });

  useEffect(() => {
    isOpen && modalProps.pool ? open() : close();
  }, [open, close, isOpen, modalProps.pool]);

  return <></>;
};
