import { Blockchains } from "@unifiprotocol/utils";
import { useAdapter } from "../Adapter";

export const DEADLINE = 60 * 20 * 1000; // 20m
export const SLIPPAGE = 0.03; // 0.3%

export type BlockchainConfig = {
  router: string;
  factory: string;
};

export const Config: { [B in Blockchains]?: BlockchainConfig } = {
  [Blockchains.FTM]: {
    router: "0x5fEDee4e7650a6198098b832Faa76eb0A8B75348",
    factory: "0x005652DD42866B8Bc690c62bC0D4a4597070E70C",
  },
};

export const ApiBaseUrl = "https://proxy.unifiprotocol.com/darbi";

export const useConfig = () => {
  const { blockchainConfig } = useAdapter();

  return { config: Config[blockchainConfig.blockchain] };
};
