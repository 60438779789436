import {
  AdapterConnectedEvent,
  AddressChangedEvent,
} from "@unifiprotocol/shell";
import React, { useEffect, useState } from "react";
import { usePools } from ".";
import { useAdapter } from "../Adapter";
import { useConfig } from "../Config";
import Clocks from "../Services/Clocks";

export const Pool = () => {
  const [[init0, init1], setInit] = useState([false, false]);
  const { adapter, eventBus, addToken, refreshBalances } = useAdapter();
  const { config } = useConfig();
  const {
    pools,
    fetchPools,
    setPools,
    getWhitelistedPools,
    setWhitelistedPools,
  } = usePools();

  useEffect(() => {
    if (!init0 && !!config) {
      setInit([true, false]);
      fetchPools().then(setPools);
    }
  }, [config, init0, fetchPools, setPools]);

  useEffect(() => {
    if (
      init0 &&
      !init1 &&
      pools.length > 0 &&
      adapter &&
      adapter.getAddress()
    ) {
      setInit([true, true]);
      getWhitelistedPools().then((pools) => {
        setWhitelistedPools(pools);
        pools.forEach((pool) => {
          addToken(pool.poolToken);
          addToken(pool.token1);
          addToken(pool.token0);
        });
        setTimeout(refreshBalances, 0);
      });
    }
  }, [
    config,
    pools,
    adapter,
    getWhitelistedPools,
    setWhitelistedPools,
    init0,
    init1,
    addToken,
    refreshBalances,
  ]);

  useEffect(() => {
    const fn = () => getWhitelistedPools().then(setWhitelistedPools);
    eventBus.on(AdapterConnectedEvent, fn);
    return () => {
      eventBus.off(AdapterConnectedEvent, fn);
    };
  }, [eventBus, getWhitelistedPools, setWhitelistedPools]);

  useEffect(() => {
    const fn = () => getWhitelistedPools().then(setWhitelistedPools);
    eventBus.on(AddressChangedEvent, fn);
    return () => {
      eventBus.off(AddressChangedEvent, fn);
    };
  }, [eventBus, getWhitelistedPools, setWhitelistedPools]);

  useEffect(() => {
    const fn = () => fetchPools().then(setPools);
    Clocks.on("TEN_SECONDS", fn);
    return () => {
      Clocks.off("TEN_SECONDS", fn);
    };
  }, [fetchPools, setPools]);

  useEffect(() => {
    const fn = () => getWhitelistedPools().then(setWhitelistedPools);
    Clocks.on("THIRTY_SECONDS", fn);
    return () => {
      Clocks.off("THIRTY_SECONDS", fn);
    };
  }, [getWhitelistedPools, setWhitelistedPools]);

  return <></>;
};
