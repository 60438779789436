import { Themes, Tooltip } from "@unifiprotocol/uikit";
import React, { useState } from "react";
import { useTheme } from "styled-components";

const baseStyle = {
  fontFamily: Themes.Dark.fontFamily,
  fontSize: "1rem",
  border: "2px solid transparent",
};

const classes = {
  tooltip: {
    ...baseStyle,
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    borderColor: "#fff",
    minWidth: "16vw",
    boxSizing: "border-box",
    maxWidth: "max-content",
    padding: "0.5rem",
    borderRadius: "5px",
    textAlign: "justify",
    zIndex: "901",
  },
  arrow: {
    color: "#ffffff",
    width: "5px",
    "z-index": "-1",
  },
};

type Placement =
  | "right"
  | "right-start"
  | "right-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "top-start"
  | "top-end"
  | "top"
  | "left"
  | "left-start"
  | "left-end";

type LightTooltipProps = {
  title: any;
  arrow?: boolean;
  event?: "onClick" | "onHover";
  placement?: Placement;
  mobilePlacement?: Placement;
};

const mobileByPlacement: any = {
  left: "bottom-end",
  "left-start": "bottom-end",
  "left-end": "bottom-end",
  right: "bottom-start",
  "right-start": "bottom-start",
  "right-end": "bottom-start",
};

export const LightTooltip: React.FC<LightTooltipProps> = (props) => {
  const [width, setWidth] = useState(visualViewport.width);
  const theme: any = useTheme();
  window.addEventListener("resize", () => setWidth(visualViewport.width));
  const mobileDevPlacement =
    props.mobilePlacement ||
    mobileByPlacement[props.placement || ""] ||
    props.placement;
  return width >= theme.breakpoints.sm ? (
    <Tooltip content={props.title} styles={classes} {...props} />
  ) : (
    <Tooltip
      content={props.title}
      styles={{
        arrow: { ...classes.arrow },
        tooltip: { ...classes.tooltip, width: "70vw", maxWidth: "max-content" },
      }}
      {...{ ...props, placement: mobileDevPlacement }}
    />
  );
};
