import { Blockchains } from "@unifiprotocol/core-sdk";

const KEY_PREFIX = "darby";

export const setStorageKey = (
  blockchain: Blockchains,
  keyName: string,
  keyValue: string
) => localStorage.setItem(`${KEY_PREFIX}.${blockchain}.${keyName}`, keyValue);

export const getStorageKey = (blockchain: Blockchains, keyName: string) =>
  localStorage.getItem(`${KEY_PREFIX}.${blockchain}.${keyName}`);
