import { BN, Currency, ifNanThen } from "@unifiprotocol/utils";

export interface ILiquidityPool {
  poolAddress: string;
  poolToken: Currency;
  token0: Currency;
  token1: Currency;
  reserves0: string;
  reserves1: string;
  settings?: ILiquidityPoolSettings;
  multiplier: string;
  totalSupply: string;
}

export interface ILiquidityPoolSettings {
  loggers: string[];
  name: string;
  enabled: boolean;
  arbPool: string;
  flashbot: string;
  rewardToken: string;
  threshold: number;
}

export class LiquidityPool implements ILiquidityPool {
  readonly multiplier: string;
  readonly poolAddress: string;
  readonly poolToken: Currency;
  readonly token0: Currency;
  readonly token1: Currency;
  readonly totalSupply: string;
  reserves0: string;
  reserves1: string;

  constructor(props: ILiquidityPool) {
    this.reserves0 = props.reserves0;
    this.reserves1 = props.reserves1;
    this.poolAddress = props.poolAddress;
    this.token0 = props.token0;
    this.token1 = props.token1;
    this.poolToken = props.poolToken;
    this.totalSupply = props.totalSupply;
    this.multiplier = props.multiplier;
  }

  setReserves(reserves: [string, string]) {
    this.reserves0 = reserves[0];
    this.reserves1 = reserves[1];
  }

  hasLiquidity() {
    return BN(this.reserves0).gt(0) && BN(this.reserves1).gt(0);
  }

  getPrice() {
    return ifNanThen(
      BN(this.token1.toFactorized(this.reserves1))
        .div(this.token0.toFactorized(this.reserves0))
        .dp(6)
        .toFixed(),
      "0"
    );
  }

  getAgainstPrice() {
    return BN(1).div(this.getPrice()).toFixed();
  }
}
