import { IAdapter } from "@unifiprotocol/core-sdk";
import { ABI as RouterABI } from "../Contracts/MultiRouter";
import { ABI as FactoryABI } from "../Contracts/Factory";
import { Config, DEADLINE, SLIPPAGE } from "../Config";
import { LiquidityPool } from "../Entities/LiquidityPool";
import { BN, ifNanThen } from "@unifiprotocol/utils";
import { AddLiquidityEth } from "../Contracts/MultiRouter/AddLiquidityEth";
import { RemoveLiquidityEth } from "../Contracts/MultiRouter/RemoveLiquidityEth";

type RequiredAdapter<T> = T & {
  adapter: IAdapter;
};

export interface AddLiquidityParams {
  token0AmountDesired: string;
  token1AmountDesired: string;
  liquidityPool: LiquidityPool;
}

export interface RemoveLiquidityParams {
  removeAmount: string;
  liquidityPool: LiquidityPool;
}

export class LiquidityService {
  private async adapterSetUp(adapter: IAdapter) {
    const blockchain = adapter.blockchainConfig.blockchain;
    if (!Config[blockchain]) {
      throw Error("Blockchain not supported");
    }
    const config = Config[blockchain]!;
    await adapter.initializeContract(config.router, RouterABI);
    await adapter.initializeContract(config.factory, FactoryABI);
    return config;
  }

  async addLiquidity({
    token0AmountDesired,
    token1AmountDesired,
    liquidityPool,
    adapter,
  }: RequiredAdapter<AddLiquidityParams>) {
    const config = await this.adapterSetUp(adapter);

    const isToken0Native = liquidityPool.token0.equals(
      adapter.blockchainConfig.wrappedToken
    );

    const token0AmountMin = ifNanThen(
      BN(token0AmountDesired)
        .multipliedBy(BN(1).minus(SLIPPAGE))
        .dp(0)
        .toFixed(),
      "0"
    );
    const token1AmountMin = ifNanThen(
      BN(token1AmountDesired)
        .multipliedBy(BN(1).minus(SLIPPAGE))
        .dp(0)
        .toFixed(),
      "0"
    );

    const to = adapter.getAddress();
    const deadline = BN(Date.now()).multipliedBy(DEADLINE).toFixed();

    const params = {
      router: config.router,
      token0Address: isToken0Native
        ? liquidityPool.token1.address
        : liquidityPool.token0.address,
      token0AmountDesired: isToken0Native
        ? token1AmountDesired
        : token0AmountDesired,
      token0AmountMin: isToken0Native ? token1AmountMin : token0AmountMin,
      ethAmountDesired: !isToken0Native
        ? token1AmountDesired
        : token0AmountDesired,
      ethAmountMin: !isToken0Native ? token1AmountMin : token0AmountMin,
      pairAddress: liquidityPool.poolAddress,
      to,
      deadline,
    };

    const useCase = new AddLiquidityEth({
      ...params,
    });

    return useCase.execute(adapter);
  }

  async removeLiquidity({
    removeAmount,
    liquidityPool,
    adapter,
  }: RequiredAdapter<RemoveLiquidityParams>) {
    const config = await this.adapterSetUp(adapter);

    const isToken0Native = liquidityPool.token0.equals(
      adapter.blockchainConfig.wrappedToken
    );
    const [amount0, amount1] = this.amountToBeReturned(
      removeAmount,
      liquidityPool
    );

    const token0AmountMin = ifNanThen(
      BN(isToken0Native ? amount0 : amount1)
        .multipliedBy(BN(1).minus(SLIPPAGE))
        .dp(0)
        .toFixed(),
      "0"
    );
    const token1AmountMin = ifNanThen(
      BN(isToken0Native ? amount1 : amount0)
        .multipliedBy(BN(1).minus(SLIPPAGE))
        .dp(0)
        .toFixed(),
      "0"
    );
    const to = adapter.getAddress();
    const deadline = BN(Date.now()).multipliedBy(DEADLINE).toFixed();

    const useCase = new RemoveLiquidityEth({
      router: config.router,
      tokenAddress: isToken0Native
        ? liquidityPool.token1.address
        : liquidityPool.token0.address,
      liquidity: removeAmount,
      amountTokenMin: "1",
      amountEthMin: "1",
      // amountTokenMin: isToken0Native ? token1AmountMin : token0AmountMin,
      // amountEthMin: isToken0Native ? token0AmountMin : token1AmountMin,
      to,
      deadline,
      pairAddress: liquidityPool.poolAddress,
    });

    return useCase.execute(adapter);
  }

  amountToBeReturned(
    amount: string,
    liquidityPool: LiquidityPool
  ): [string, string] {
    const token0 = BN(amount).multipliedBy(liquidityPool.getPrice()).toFixed();
    const token1 = BN(liquidityPool.getPrice()).div(amount).toFixed();
    return [token0, token1];
  }
}
