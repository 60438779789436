import React, { useCallback, useContext, useMemo } from "react";
import {
  AddCurrency,
  ShellWrappedProps,
  RefreshBalances,
} from "@unifiprotocol/shell";
import { Currency } from "@unifiprotocol/utils";

type TAdapter = {
  connection: ShellWrappedProps["connection"] | undefined;
  eventBus: ShellWrappedProps["eventBus"] | undefined;
  balances: ShellWrappedProps["balances"];
};

export const AdapterContext = React.createContext<TAdapter>({
  connection: undefined,
  eventBus: undefined,
  balances: [],
});

export const useAdapter = () => {
  const state = useContext(AdapterContext);

  const addToken = useCallback(
    (currency: Currency) => {
      const { eventBus } = state;
      if (eventBus) {
        eventBus.emit(new AddCurrency(currency));
      }
    },
    [state]
  );

  const refreshBalances = useCallback(() => {
    const { eventBus } = state;
    if (eventBus) {
      eventBus.emit(new RefreshBalances());
    }
  }, [state]);

  const getBalanceByCurrency = useCallback(
    (currency: Currency, factorized = false) => {
      const balance = state.balances.find((c) => c.currency.equals(currency));
      if (balance) {
        return {
          ...balance,
          balance: factorized
            ? currency.toFactorized(balance.balance)
            : balance.balance,
        };
      }
      return { currency, balance: "0" };
    },
    [state.balances]
  );

  const adapter = useMemo(() => state.connection?.adapter, [state]);

  const connection = useMemo(() => state.connection!, [state]);

  return {
    addToken,
    getBalanceByCurrency,
    refreshBalances,
    connection,
    eventBus: state.eventBus!,
    blockchainConfig: state.connection!.config,
    adapter: adapter?.adapter,
    balances: state.balances,
  };
};
