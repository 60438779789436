import { DangerButton, Input, PrimaryButton } from "@unifiprotocol/uikit";
import styled from "styled-components";

export const BasicInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const PrimaryTitle = styled.b`
  font-size: 1.5rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.primary};
`;

export const LoggersInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const InputPlaceholder = styled.b`
  margin-left: 1rem;
  color: ${({ theme }) => theme.bgAlt1};
`;

export const LighterInput = styled(Input)`
  color: #ffffff99;
`;

export const MarginedPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  margin-top: 1rem;
`;

export const CheckboxContainer = styled.div`
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const MarginedDangerButton = styled(DangerButton)`
  margin-top: 1rem;
`;
export const CenteredContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  * {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
`;
