import styled from "styled-components";

export const AutopoolsWrapper = styled.div`
  padding: 1rem;
  background: ${({ theme }) => theme.bg};
  color: white;
`;

export const DescriptionText = styled.div`
  text-align: justify;
`;
