export const AppTheme = {
  app: {
    topbar: {
      height: "3.5rem",
    },
    sidebar: {
      transitionTime: "0.25s",
      width: "15rem",
      miniWidth: "4.5rem",
    },
  },
};
